<template>
  <div>
    <a class="anchor" id="hero"></a>
    <Hero
      v-observe-visibility="
        (isVisible, entry) => visibilityChanged(isVisible, entry, 'hero')
      "
    ></Hero>
    <a class="anchor" id="main_features"></a>
    <MainFeatures
      margin-top="n16"
      v-observe-visibility="
        (isVisible, entry) =>
          visibilityChanged(isVisible, entry, 'mainFeatures')
      "
    ></MainFeatures>
    <a class="anchor" id="features"></a>
    <Features
      v-observe-visibility="
        (isVisible, entry) => visibilityChanged(isVisible, entry, 'features')
      "
    ></Features>
    <a class="anchor" id="about"></a>
    <About
      v-observe-visibility="
        (isVisible, entry) => visibilityChanged(isVisible, entry, 'about')
      "
    ></About>
    <a class="anchor" id="subscribe"></a>
    <Subscribe
      v-observe-visibility="
        (isVisible, entry) => visibilityChanged(isVisible, entry, 'subscribe')
      "
    ></Subscribe>
  </div>
</template>

<script>
import Hero from "@/components/Hero";
import About from "@/components/About";
import MainFeatures from "@/components/MainFeatures";
import Features from "@/components/Features";
import Subscribe from "@/components/Subscribe";
export default {
  name: "Home",
  components: {
    Features,
    MainFeatures,
    About,
    Hero,
    Subscribe
  },
  data: () => ({
    sectionView: {
      features: null,
      mainFeatures: null,
      about: null,
      hero: null,
      subscribe: null
    }
  }),
  methods: {
    visibilityChanged(isVisible, entry, section) {
      if (isVisible) {
        this.sectionView[section] = new Date().getTime();
      } else {
        if (this.sectionView[section]) {
          this.$gtag.pageview({
            // eslint-disable-next-line @typescript-eslint/camelcase
            page_title: section,
            // eslint-disable-next-line @typescript-eslint/camelcase
            page_path: "/#" + section
          });
        }
      }
    }
  }
};
</script>

<style scoped>
a.anchor {
  display: block;
  position: relative;
  top: -64px;
  visibility: hidden;
}
</style>
