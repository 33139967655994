var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ content: _vm.$vuetify.breakpoint.mdAndUp }},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-h3 lime-underline pb-0",attrs:{"cols":"11","md":"6","align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("features.title"))+" ")])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"10","sm":"4","md":"4"}},_vm._l((_vm.featuresLeft),function(feature,i){return _c('v-row',{key:feature.title,staticClass:"hidden-xs-only",staticStyle:{"cursor":"pointer"},attrs:{"align":"center"},on:{"click":function($event){_vm.selectedFeature = i}}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"9","md":"9"}},[_c('div',{staticClass:"text-h5",class:_vm.selectedFeature == i
                ? 'font-weight-black primary--text lime-underline'
                : ''},[_vm._v(" "+_vm._s(feature.title)+" ")]),_c('div',{staticClass:"text-body-2",class:_vm.selectedFeature == i ? 'font-weight-black' : '',attrs:{"align":"justify"}},[_vm._v(" "+_vm._s(feature.text)+" ")])]),_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-icon',{style:(_vm.$vuetify.breakpoint.mdAndUp
                ? 'font-size: 100px'
                : 'font-size: 60px'),attrs:{"color":feature.color}},[_vm._v(" "+_vm._s(feature.icon)+" ")])],1)],1)}),1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"11","sm":"3","md":"3","align":"center"}},[_c('v-carousel',{attrs:{"continuous":true,"cycle":false,"show-arrows":true,"hide-delimiter-background":true,"hide-delimiters":true,"height":_vm.$vuetify.breakpoint.smAndUp ? 500 : 640},model:{value:(_vm.selectedFeature),callback:function ($$v) {_vm.selectedFeature=$$v},expression:"selectedFeature"}},_vm._l((_vm.features),function(feature,i){return _c('v-carousel-item',{key:i},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/mobile/' + feature.image + '.png'),"alt":"iPhone Frame","height":"500"}}),_c('v-row',{staticClass:"d-sm-none",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-icon',{staticStyle:{"font-size":"60px"},attrs:{"color":feature.color}},[_vm._v(" "+_vm._s(feature.icon)+" ")]),_c('span',{staticClass:"text-h5 font-weight-black primary--text lime-underline"},[_vm._v(" "+_vm._s(feature.title)+" ")])],1)],1),_c('v-row',{staticClass:"d-sm-none",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-body-2 black--text font-weight-black",attrs:{"align":"justify"}},[_vm._v(" "+_vm._s(feature.text)+" ")])])],1)],1)])}),1)],1),_c('v-col',{attrs:{"cols":"10","sm":"4","md":"4"}},_vm._l((_vm.featuresRight),function(feature,i){return _c('v-row',{key:feature.title,staticClass:"hidden-xs-only",staticStyle:{"cursor":"pointer"},attrs:{"align":"center"},on:{"click":function($event){_vm.selectedFeature = i + _vm.featuresLeft.length}}},[_c('v-col',{attrs:{"cols":"3","md":"3"}},[_c('v-icon',{style:(_vm.$vuetify.breakpoint.mdAndUp
                ? 'font-size: 100px'
                : 'font-size: 60px'),attrs:{"color":feature.color}},[_vm._v(" "+_vm._s(feature.icon)+" ")])],1),_c('v-col',{attrs:{"cols":"9","md":"9"}},[_c('div',{staticClass:"text-h5",class:_vm.selectedFeature == i + _vm.featuresLeft.length
                ? 'font-weight-black primary--text lime-underline'
                : ''},[_vm._v(" "+_vm._s(feature.title)+" ")]),_c('div',{staticClass:"text-body-2",class:_vm.selectedFeature == i + _vm.featuresLeft.length
                ? 'font-weight-black'
                : '',attrs:{"align":"justify"}},[_vm._v(" "+_vm._s(feature.text)+" ")])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }