<template>
  <div :class="{ content: $vuetify.breakpoint.mdAndUp }">
    <v-row align="center" justify="center">
      <v-col cols="11" md="6" align="center" class="text-h3 lime-underline">
        {{ $t("subscribe.title") }}
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      :class="$vuetify.breakpoint.mdAndUp ? 'pt-16' : ''"
    >
      <v-col cols="11" sm="5" md="4" class="text-subtitle-1" align="justify">
        <p class="text-h5">
          {{ $t("subscribe.subscribeQuestion") }}
        </p>
        {{ $t("subscribe.mainText") }}
      </v-col>
      <v-col cols="11" sm="4" md="4" offset-sm="1">
        <v-card>
          <v-row align="center" justify="center">
            <v-col cols="11" sm="11" md="10">
              <v-form @submit.prevent="subscribe()">
                <v-text-field
                  :label="$t('subscribe.name')"
                  name="name"
                  v-model="name"
                  :rules="nameRules"
                  type="text"
                  color="teal"
                />
                <v-text-field
                  :label="$t('subscribe.email')"
                  name="email"
                  v-model="email"
                  :rules="emailRules"
                  type="text"
                  color="teal"
                />
                <v-alert
                  :value="subscribedBox"
                  border="left"
                  dense
                  outlined
                  :type="subscribedType"
                  v-html="message"
                >
                </v-alert>
                <div class="text-center mt-6">
                  <v-btn type="submit" large color="lime">{{
                    $t("subscribe.register")
                  }}</v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" align="center">
        <p class="text-h5">
          {{ $t("hero.subscribeQuestion") }}
        </p>
        <v-btn color="lime" rounded x-large :href="registerUrl" target="_blank">
          {{ $t("hero.subscribeButton") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="2" sm="3" md="2" class="pb-0 pt-0">
        <a
          href="https://play.google.com/store/apps/details?id=app.behorse&utm_source=site"
          ><v-img
            contain
            alt="Disponível no Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
        /></a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import jsonp from "jsonp";

export default {
  name: "Subscribe",
  data: () => ({
    email: "",
    name: "",
    subscribedBox: false,
    subscribedType: "success",
    message: ""
  }),
  methods: {
    subscribe() {
      if (this.email && this.name) {
        jsonp(
          "https://app.us2.list-manage.com/subscribe/post-json?u=8b7b168508ec14f10a7b75345&id=df4f3ab5a4&MERGE0=" +
            this.email +
            "&MERGE1=" +
            this.name,
          { param: "c" },
          (error, response) => {
            if (error) {
              this.subscribedBox = true;
              this.subscribedType = "error";
              this.message = this.$t("subscribe.errorMessage");
            } else {
              if (response && response.result == "success") {
                this.subscribedBox = true;
                this.subscribedType = "success";
                this.message = this.$t("subscribe.successMessage");
              } else {
                if (response.msg.includes(this.email)) {
                  this.subscribedBox = true;
                  this.subscribedType = "info";
                  this.message = response.msg;
                } else {
                  this.subscribedBox = true;
                  this.subscribedType = "error";
                  this.message = this.$t("subscribe.errorMessage");
                }
              }
            }
          }
        );
      } else {
        this.subscribedBox = true;
        this.subscribedType = "error";
        this.message = this.$t("subscribe.submitError");
      }
    }
  },
  computed: {
    emailRules() {
      return [
        v => !!v || this.$t("subscribe.requiredField"),
        v => /.+@.+\..+/.test(v) || this.$t("subscribe.validEmail")
      ];
    },
    nameRules() {
      return [v => !!v || this.$t("subscribe.requiredField")];
    },
    registerUrl() {
      return process.env.VUE_APP_REGISTER_URL || "";
    }
  }
};
</script>

<style scoped>
.content {
  height: calc(100vh - 64px - 122px);
}
</style>
