<template>
  <div
    :class="$vuetify.breakpoint.mdAndUp ? 'content ml-n3 mr-n6' : ''"
    class="primary"
  >
    <v-row align="center" justify="center" class="primary">
      <v-col cols="11" sm="6" md="6" align="center">
        <v-img
          :src="require('@/assets/laptop/home_' + $i18n.locale + '.png')"
          transition="scale-transition"
          alt="MacBook - BeHorse Home"
        ></v-img>
      </v-col>
      <v-col
        cols="11"
        sm="4"
        md="4"
        class="white--text text-right pb-5"
        align="center"
        order="first"
        order-sm="last"
        order-md="last"
        order-lg="last"
        order-xl="last"
      >
        <p class="text-h3">{{ $t("hero.phrase1") }}</p>
        <p class="text-h5">{{ $t("hero.phrase2") }}</p>
        <v-dialog :width="width" @click:outside="pause()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              elevation="2"
              rounded
              outlined
              x-large
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-play</v-icon>
              {{ $t("hero.videoButton") }}
            </v-btn>
          </template>
          <youtube
            video-id="XloKa7QU05c"
            @ready="ready"
            :player-width="width"
            :player-height="height"
            :player-vars="{ autoplay: 1 }"
          ></youtube>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="primary">
      <v-col cols="11" sm="8" md="8" align="center" class="pb-0 pt-0">
        <p class="text-h5 lime--text">
          {{ $t("hero.subscribeQuestion") }}
        </p>
        <v-btn color="lime" rounded x-large :href="registerUrl" target="_blank">
          {{ $t("hero.subscribeButton") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="primary">
      <v-col cols="2" sm="3" md="2" class="pb-0 pt-0">
        <a
          href="https://play.google.com/store/apps/details?id=app.behorse&utm_source=site"
          ><v-img
            contain
            alt="Disponível no Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
        /></a>
      </v-col>
      <v-col cols="2" sm="3" md="2" class="pb-0 pt-0">
        <v-img
          contain
          alt="Logo na Apple Store"
          :src="require('@/assets/logo-na-app-store.png')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";

Vue.use(VueYouTubeEmbed);
export default {
  name: "Hero",
  methods: {
    ready(event) {
      this.player = event.target;
    },
    pause() {
      this.player.pauseVideo();
    }
  },
  computed: {
    width() {
      if (
        window.screen &&
        window.screen.orientation &&
        window.screen.orientation.type &&
        window.screen.orientation.type.toString().includes("landscape")
      ) {
        return window.innerWidth * 0.9;
      } else {
        return this.$vuetify.breakpoint.smAndUp
          ? window.innerWidth * 0.9
          : window.innerWidth * 0.85;
      }
    },
    height() {
      if (
        window.screen &&
        window.screen.orientation &&
        window.screen.orientation.type &&
        window.screen.orientation.type.toString().includes("landscape")
      ) {
        return this.$vuetify.breakpoint.lgAndUp
          ? window.innerHeight * 0.9
          : this.$vuetify.breakpoint.smAndUp
          ? window.innerHeight * 0.9
          : window.innerHeight * 0.9;
      } else {
        return this.$vuetify.breakpoint.lgAndUp
          ? window.innerHeight * 0.9
          : this.$vuetify.breakpoint.smAndUp
          ? window.innerHeight * 0.6
          : 200;
      }
    },
    registerUrl() {
      return process.env.VUE_APP_REGISTER_URL || "";
    }
  }
};
</script>

<style scoped>
.content {
  height: calc(100vh);
}
</style>
