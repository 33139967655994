<template>
  <div :class="{ content: $vuetify.breakpoint.mdAndUp }">
    <v-row align="center" justify="center">
      <v-col
        cols="11"
        md="6"
        align="center"
        class="text-h3 lime-underline pb-0"
      >
        {{ $t("features.title") }}
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" sm="4" md="4" class="pt-0">
        <v-row
          align="center"
          v-for="(feature, i) in featuresLeft"
          v-bind:key="feature.title"
          @click="selectedFeature = i"
          style="cursor: pointer"
          class="hidden-xs-only"
        >
          <v-col cols="9" md="9" class="pt-0">
            <div
              class="text-h5"
              :class="
                selectedFeature == i
                  ? 'font-weight-black primary--text lime-underline'
                  : ''
              "
            >
              {{ feature.title }}
            </div>
            <div
              class="text-body-2"
              align="justify"
              :class="selectedFeature == i ? 'font-weight-black' : ''"
            >
              {{ feature.text }}
            </div>
          </v-col>
          <v-col cols="3" md="3">
            <v-icon
              :color="feature.color"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 100px'
                  : 'font-size: 60px'
              "
            >
              {{ feature.icon }}
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="11" sm="3" md="3" align="center" class="pt-0">
        <v-carousel
          v-model="selectedFeature"
          :continuous="true"
          :cycle="false"
          :show-arrows="true"
          :hide-delimiter-background="true"
          :hide-delimiters="true"
          :height="$vuetify.breakpoint.smAndUp ? 500 : 640"
        >
          <v-carousel-item v-for="(feature, i) in features" :key="i">
            <div>
              <v-img
                :src="require('@/assets/mobile/' + feature.image + '.png')"
                alt="iPhone Frame"
                height="500"
              ></v-img>
              <v-row align="center" class="d-sm-none">
                <v-col cols="12" class="py-0">
                  <v-icon :color="feature.color" style="font-size: 60px">
                    {{ feature.icon }}
                  </v-icon>
                  <span
                    class="text-h5 font-weight-black primary--text lime-underline"
                  >
                    {{ feature.title }}
                  </span>
                </v-col>
              </v-row>
              <v-row align="center" class="d-sm-none">
                <v-col cols="12" class="py-0">
                  <div
                    class="text-body-2 black--text font-weight-black"
                    align="justify"
                  >
                    {{ feature.text }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="10" sm="4" md="4">
        <v-row
          align="center"
          v-for="(feature, i) in featuresRight"
          v-bind:key="feature.title"
          @click="selectedFeature = i + featuresLeft.length"
          style="cursor: pointer"
          class="hidden-xs-only"
        >
          <v-col cols="3" md="3">
            <v-icon
              :color="feature.color"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 100px'
                  : 'font-size: 60px'
              "
            >
              {{ feature.icon }}
            </v-icon>
          </v-col>
          <v-col cols="9" md="9">
            <div
              class="text-h5"
              :class="
                selectedFeature == i + featuresLeft.length
                  ? 'font-weight-black primary--text lime-underline'
                  : ''
              "
            >
              {{ feature.title }}
            </div>
            <div
              class="text-body-2"
              align="justify"
              :class="
                selectedFeature == i + featuresLeft.length
                  ? 'font-weight-black'
                  : ''
              "
            >
              {{ feature.text }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Features",
  data: () => ({
    selectedFeature: null
  }),
  async created() {
    this.selectedFeature = this.featuresRight[0];
  },
  computed: {
    featuresLeft() {
      return [
        {
          title: this.$t("features.feature1.title"),
          text: this.$t("features.feature1.text"),
          icon: "mdi-hospital-box",
          color: "#4CAF50",
          image: "health_pt-br"
        },
        {
          title: this.$t("features.feature2.title"),
          text: this.$t("features.feature2.text"),
          icon: "mdi-format-list-text",
          color: "#2196F3",
          image: "feed_pt-br"
        },
        {
          title: this.$t("features.feature3.title"),
          text: this.$t("features.feature3.text"),
          icon: "mdi-card-account-details-outline",
          color: "#607D8B",
          image: "digital_info_pt-br"
        }
      ];
    },
    featuresRight() {
      return [
        {
          title: this.$t("features.feature4.title"),
          text: this.$t("features.feature4.text"),
          icon: "mdi-horse-human",
          color: "#795548",
          image: "stables_pt-br"
        },
        {
          title: this.$t("features.feature5.title"),
          text: this.$t("features.feature5.text"),
          icon: "mdi-carrot",
          color: "#FF9800",
          image: "food_pt-br"
        },
        {
          title: this.$t("features.feature6.title"),
          text: this.$t("features.feature6.text"),
          icon: "mdi-account-multiple",
          color: "#009688",
          image: "spaces_pt-br"
        }
      ];
    },
    features() {
      return this.featuresLeft.concat(this.featuresRight);
    }
  }
};
</script>

<style scoped>
.content {
  height: calc(100vh - 64px);
}
</style>
