import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import VueObserveVisibility from "vue-observe-visibility";

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: "UA-180582685-1" }
  },
  router
);
Vue.use(VueObserveVisibility);

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
