<template>
  <v-row align="start" justify="center" class="white">
    <v-col
      cols="11"
      sm="3"
      md="3"
      align="center"
      v-for="(item, i) in features"
      v-bind:key="i"
    >
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? `mt-${marginTop}` : ''"
        class="align-center"
        min-height="280"
        elevation="10"
      >
        <v-card-title style="justify-content: center" class="font-weight-black">
          {{ item.title }}
        </v-card-title>
        <v-icon style="font-size: 120px" color="primary">
          {{ item.icon }}
        </v-icon>
        <v-card-text align="justify">
          {{ item.text }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MainFeatures",
  props: {
    marginTop: { type: String, default: "0" }
  },
  computed: {
    features() {
      return [
        {
          title: this.$t("mainFeatures.feature1.title"),
          text: this.$t("mainFeatures.feature1.text"),
          icon: "mdi-cogs"
        },
        {
          title: this.$t("mainFeatures.feature2.title"),
          text: this.$t("mainFeatures.feature2.text"),
          icon: "mdi-tablet-cellphone"
        },
        {
          title: this.$t("mainFeatures.feature3.title"),
          text: this.$t("mainFeatures.feature3.text"),
          icon: "mdi-message-alert-outline"
        }
      ];
    }
  }
};
</script>

<style scoped></style>
