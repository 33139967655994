import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@/css/styles.css";
import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#009688",
        secondary: "#cddc39",
        accent: "#673ab7",
        error: "#f44336",
        warning: "#ffc107",
        info: "#607d8b",
        success: "#4caf50"
      }
    }
  },
  lang: {
    locales: { pt },
    current: "pt"
  }
});
