var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"anchor",attrs:{"id":"hero"}}),_c('Hero',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
      function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'hero'); }
    ),expression:"\n      (isVisible, entry) => visibilityChanged(isVisible, entry, 'hero')\n    "}]}),_c('a',{staticClass:"anchor",attrs:{"id":"main_features"}}),_c('MainFeatures',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
      function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'mainFeatures'); }
    ),expression:"\n      (isVisible, entry) =>\n        visibilityChanged(isVisible, entry, 'mainFeatures')\n    "}],attrs:{"margin-top":"n16"}}),_c('a',{staticClass:"anchor",attrs:{"id":"features"}}),_c('Features',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
      function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'features'); }
    ),expression:"\n      (isVisible, entry) => visibilityChanged(isVisible, entry, 'features')\n    "}]}),_c('a',{staticClass:"anchor",attrs:{"id":"about"}}),_c('About',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
      function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'about'); }
    ),expression:"\n      (isVisible, entry) => visibilityChanged(isVisible, entry, 'about')\n    "}]}),_c('a',{staticClass:"anchor",attrs:{"id":"subscribe"}}),_c('Subscribe',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
      function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'subscribe'); }
    ),expression:"\n      (isVisible, entry) => visibilityChanged(isVisible, entry, 'subscribe')\n    "}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }