<template>
  <v-app>
    <v-app-bar flat app color="primary" dark align="left">
      <v-img
        position="center left"
        alt="Beyond Horse Logo"
        contain
        src="@/assets/logo_white.png"
        height="100%"
        :width="$vuetify.breakpoint.mdAndUp ? '200px' : '120px'"
      />
      <v-spacer class="hidden-xs-only"></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" class="d-sm-none">
          </v-app-bar-nav-icon>
        </template>
        <v-list color="teal" dark>
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            :to="item.link ? item.link : ''"
            :href="item.external ? item.external : ''"
            :target="item.external ? '_blank' : ''"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-for="(item, index) in menu">
        <v-btn
          :key="index"
          text
          :to="item.link ? item.link : ''"
          :href="item.external ? item.external : ''"
          :target="item.external ? '_blank' : ''"
          class="d-none d-sm-flex font-weight-black ml-2"
        >
          {{ item.name }}
        </v-btn>
      </template>
      <v-btn
        color="lime"
        rounded
        :href="registerUrl"
        target="_blank"
        light
        class="ml-2"
      >
        {{ $t("menu.register") }}
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="white"
            class="black--text ml-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              :src="require('../public/img/' + locale + '.png')"
              width="25px"
            ></v-img>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item-group v-model="locale">
            <v-list-item value="pt-br">
              <v-list-item-icon>
                <v-img
                  :src="require('../public/img/pt-br.png')"
                  width="25px"
                ></v-img>
              </v-list-item-icon>
              <v-list-item-title>
                Português
              </v-list-item-title>
            </v-list-item>
            <v-list-item value="en-us">
              <v-list-item-icon>
                <v-img
                  :src="require('../public/img/en-us.png')"
                  width="25px"
                ></v-img>
              </v-list-item-icon>
              <v-list-item-title>
                English
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid class="pt-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer padless height="125" class="primary">
      <v-card flat tile class="primary white--text text-center flex">
        <v-card-text>
          <v-btn
            v-for="item in socialMedia"
            :key="item.icon"
            class="mx-4 white--text"
            icon
            :href="item.link"
            target="_blank"
          >
            <v-icon size="24px">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text">
          Copyright &copy; 2020 - {{ new Date().getFullYear() }} <br />
          <strong>Beyond Horse Tecnologia e Solucoes em Software LTDA</strong>
          <br />
          CNPJ 40.580.632/0001-96
        </v-card-text>
        <v-card-text class="white--text font-weight-light">
          Google Play e o logotipo do Google Play são marcas registradas da
          Google LLC. Apple Store® are trademarks of Apple Inc., registered in
          the U.S. and other countries.
        </v-card-text>
      </v-card>
      <cookie-law theme="dark-lime" :buttonText="$t('cookie.button')">
        <div slot="message">
          {{ $t("cookie.message") }}
        </div>
      </cookie-law>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from "vue";
import CookieLaw from "vue-cookie-law";

export default Vue.extend({
  name: "App",
  components: { CookieLaw },
  data: () => ({
    socialMedia: [
      {
        icon: "mdi-google-play",
        link:
          "https://play.google.com/store/apps/details?id=app.behorse&utm_source=Site"
      },
      { icon: "mdi-facebook", link: "https://www.facebook.com/behorseapp" },
      { icon: "mdi-twitter", link: "https://twitter.com/BeyondHorse" },
      {
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/company/behorse/"
      },
      { icon: "mdi-instagram", link: "https://www.instagram.com/beyond.horse/" }
    ],
    locale: "pt-br"
  }),
  async created() {
    if (localStorage.language) {
      this.locale = localStorage.language;
    } else {
      const languages = navigator.languages;
      if (languages) {
        if (
          languages.indexOf("pt-br") !== -1 ||
          languages.indexOf("pt") !== -1
        ) {
          this.locale = "pt-br";
        } else if (
          languages.indexOf("en") !== -1 ||
          languages.indexOf("en-us") !== -1
        ) {
          this.locale = "en-us";
        }
      }
    }
  },
  watch: {
    locale(newValue) {
      this.$root.$i18n.locale = newValue;
      localStorage.language = newValue;
    }
  },
  computed: {
    menu() {
      return [
        { name: this.$t("menu.home"), link: "#hero" },
        { name: this.$t("menu.features"), link: "#features" },
        { name: this.$t("menu.about"), link: "#about" },
        {
          name: this.$t("menu.login"),
          external: process.env.VUE_APP_LOGIN_URL || ""
        }
      ];
    },
    registerUrl() {
      return process.env.VUE_APP_REGISTER_URL || "";
    }
  }
});
</script>
