<template>
  <div
    :class="$vuetify.breakpoint.mdAndUp ? 'content ml-n3 mr-n6' : ''"
    class="primary"
  >
    <v-row align="center" justify="center" class="primary white--text">
      <v-col cols="11" md="6" align="center" class="text-h3 lime-underline">
        {{ $t("about.title") }}
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="primary white--text">
      <v-col cols="11" sm="4" md="2" align="center">
        <v-img
          src="@/assets/logo.png"
          transition="scale-transition"
          :width="$vuetify.breakpoint.smAndUp ? '100%' : '50%'"
          alt="Beyond Horse Logo"
        ></v-img>
      </v-col>
      <v-col cols="11" sm="6" md="5" align="justify" class="text-subtitle-1">
        {{ $t("about.mainText") }}
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="primary white--text">
      <v-col cols="11" md="6" align="center" class="text-h6">
        {{ $t("about.contact") }}
        <a href="mailto:contato@behorse.app" class="secondary--text">
          contato@behorse.app
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped>
.content {
  height: calc(100vh - 64px);
}
</style>
